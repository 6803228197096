import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/worentwalker/"
        target="_blank"
        rel="noopener"
      >
        <BsLinkedin />
      </a>
      <a href="https://github.com/WorentWalker" target="_blank" rel="noopener">
        <FaGithub />
      </a>
      <a
        href="https://www.instagram.com/worent_walker/"
        target="_blank"
        rel="noopener"
      >
        <FiInstagram />
      </a>
    </div>
  );
};

export default HeaderSocials;
